.title-ptag{
    margin-left: 1rem;
    /* font-weight: bold; */
    font-size: 1.1rem;
}
.email-validation {
    color: red;
    font-size: 1rem;
    padding-left: 17px;
}
