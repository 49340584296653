.key-sec-images-cont{
display: flex;
flex-direction: column;
}
.dest-img-cont{
    /* position: relative; */
}
.small-img{
    width: 15rem;
    margin: 0.3rem;
    height: 9rem;
    background-size: contain;
    
}
.large-img{
    width: 30.6rem;
    margin:0.3rem;
    height:20rem;
    background-size: contain;
}

@media (max-width:500px){
    .small-img{
        width: 7.5rem;
        margin: 0.3rem;
    }
    .large-img{
        width: 15.3rem;
        margin:0.3rem
    }
}

/* section one small icons bule */
.sub-card-blue-ic{
    margin-bottom: 3rem;
}

.sub-cont-key-sec-blue-ic{
    /* background-color: whitesmoke; */
    margin: 0.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* align-content: center;
    justify-content: center; */
    width: 155px;
    height: 155px;
}
.item-blue-ic{
    width: 70px;
    height: 75px;
    object-fit: fill;
}
.text-blue-ic{
    color: var(--primary-cl);
    font-size: 1rem;
    margin: 1rem 0;
    text-align: center;
}

@media (max-width: 500px){
    .card-holder-clients{
      width: 100%;
    }
    .sub-cont-key-sec-blue-ic{
      height: 120px;
      width: 120px;
    }
    .item-blue-ic {
      width: 90px;
    }
  }
  