.course-card-cont {
  height: 24.5rem;
  background-color: white;
}
.course-card-img {
  width: 15rem;
  height:10rem;
}
.course-card-name{
    /* Indranil: 09Dec24: Fixed padding to solve Firefox padding issue - removed '0.9rem'
    /*padding: 0.5rem 0.9rem;*/
    padding: 0.5rem;
    padding-top: 1.5rem;
    font-size: 1.3rem;
    height: 3.5rem;
    line-height: 1.8rem;
}
.course-card-desc{
    padding: 0.5rem 0.9rem;
    height: 7rem;
    /* color: #5E5E5E; */
    font-size: 1rem;
    line-height: 1.2rem;
}
.button-cont{
    display: flex;
    justify-content: space-evenly;
    margin: 0.5rem 0.9rem;
}
.sub-check{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media(max-width:460px){
  .course-card-cont {
    /*width: 15rem; */
    height: 25rem;
  }
  .course-card-desc{
    font-size: 0.8rem;
    height: 5.5rem;
  }
  .course-card-name{
    padding: 0.5rem 1.2rem;
    padding-top: 1rem;
    font-size: 1.5rem;
    height: 2.5rem;
}
}
