.focus-area-card{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
}
.focus-area-sub-cont1{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.focus-area-img{
    height: 100%;
    width: auto;
}
.focus-area-sub-cont2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0;
}
.fac-sd1{  
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.width60p{
    height: 60%;
}
.fac-sd2{
    height: 30%;
    margin: 0 2rem;
}
.card-sub-title-big{
    margin: 3rem 0;
    font-size: 1.8rem;
    line-height: 2.3rem;
}

@media (max-width:600px){
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 17rem;
        width: 100%;
        border-radius: 20px;
        object-fit: cover;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
    .card-sub-title-big{
        margin: 1rem 0;
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
}

