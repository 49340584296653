.bg-titp{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/career-banner.jpg");
    height: 60vh;
}
.hero-title-nats {
  font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
  font-size: 60px; /* Adjust to match the size of the AI text */
  font-weight: bold; /* Make the font weight bold if needed */
  color: white; /* Match the color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
  padding: 5px;
  position: absolute;
  top: 120px; 
  right: 5px;
}

@media (max-width: 500px) {
    .bg-titp {
      height: 30vh;
      background-position: center;
      background-position: 35% 0;
    } 
    .titp-home-data-subcont-right{
      width: 25%;
      margin-top: 1.5rem;
    }

    .hero-title-nats {
      font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
      font-size: 10px; /* Adjust to match the size of the AI text */
      font-weight: bold; /* Make the font weight bold if needed */
      color: white; /* Match the color */
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
      padding: 5px;
      position: absolute;
      top: 100px; 
      right: 5px;
    }

    
  }
  @media screen and (min-width: 500px) and (max-width: 850px){
    .bg-titp {
      height: 50vh;
      background-position: center;
    }

    .hero-title-nats {
      font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
      font-size: 10px; /* Adjust to match the size of the AI text */
      font-weight: bold; /* Make the font weight bold if needed */
      color: white; /* Match the color */
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
      padding: 5px;
      position: absolute;
      top: 100px; 
      right: 5px;
    }
  }

  


















  .faq-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  
  
  .faq-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #003366;
    margin-bottom: 30px;
  }
  
  .faq-item {
    border: 1px solid #003366;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #6f98ce;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .faq-arrow {
    color: whitesmoke;
    font-size: 18px;
    transition: transform 0.3s;
  }
  
  /*.faq-answer {
    font-size: 16px;
    color: #555555;
    line-height: 1.6;
    padding: 15px;
    display: none;   }
  
  .faq-item.active .faq-answer {
    display: block;
  }*/
  
  .faq-item.activee .faq-arrow {
    transform: rotate(90deg); /* Rotate arrow when active */
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .faq-answer {
    font-size: 17px;
    font-weight: bold;
    color: #691902;
    line-height: 1.6;
    padding: 15px;
    opacity: 0; /* Hidden by default */
    transform: translateX(-100%); /* Off-screen by default */
    transition: opacity 0.3s, transform 0.3s; /* Fallback for smooth state changes */
  }
  
  .faq-item.activee .faq-answer {
    display: block;
    opacity: 1; /* Fade in */
    transform: translateX(0); /* Move into view */
    animation: slideInLeft 0.9s ease; /* Apply animation */
    color: #691902;
  }





  .faq-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background-size: cover;
    position: relative;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    color: #ffffff; /* Ensure text stands out */
    font-family: Arial, sans-serif;
  }
  
  /* Overlay for reducing background image opacity */
  .faq-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.5); */
    background: inherit;
    z-index: -1;

    filter        : blur(10px);
    -moz-filter   : blur(10px);
    -webkit-filter: blur(10px);
    -o-filter     : blur(10px);

    transition        : all 2s linear;
    -moz-transition   : all 2s linear;
    -webkit-transition: all 2s linear;
    -o-transition     : all 2s linear;
    border-radius: 10px;
    z-index: 1;
  }
  
  /* Ensure text is above overlay */
  .faq-container * {
    position: relative;
    z-index: 2;
  }


  /* Individual container-specific background images */
.faq-container1 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  
}

.faq-container2 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
}

.faq-container3 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center;
}





.faq-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  background-size: cover;
  box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
  
}

.faq-container:hover {
  
  background: #FFF;
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  background-size: cover;
  border: 1px solid #d27803;
        /*box-shadow: 0 0 10px #81D4FA;*/
  box-shadow: 3px 3px 0px 5px #691902;
        transition: all ease-in .3s;
}





  .faq-container2 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    
  }
  

  .faq-container2:hover {
    
    background: #FFF;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
		border: 1px solid #d27803;
        	/*box-shadow: 0 0 10px #81D4FA;*/
		box-shadow: 3px 3px 0px 5px #f4400e;
        	transition: all ease-in .3s;
  }

  .faq-question2 {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #0031ba;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .faq-container3 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    color: #ffffff; /* Text is white for contrast */
    font-family: Arial, sans-serif;
  }
  
  /* Dark overlay on background */
  .faq-container3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(150, 148, 148, 0.5); /* Semi-transparent black */
    border-radius: 10px;
    z-index: 0.5;
  }
  
  /* Ensure text is above overlay */
  .faq-container3 * {
    position: relative;
    z-index: 2;
  }


  
  .faq-container31 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    
    
  }

  .faq-container3:hover {
    
    background: #FFF;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
		border: 1px solid #1904ba;
        	/*box-shadow: 0 0 10px #81D4FA;*/
		box-shadow: 3px 3px 0px 5px #1904ba;
        	transition: all 0.3s ease-in-out;
  }

  .faq-question3 {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #7e57c2;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }




  .container {
    text-align: center;
    padding: 20px;
    margin-bottom: 50px;
    background: #FFF;
    
    background: linear-gradient(to bottom right, #ffffff, #f3f3f3); /* Subtle gradient */
    border: 1px solid #e0e0e0; /* Light border */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 
      10px 10px 20px rgba(0, 0, 0, 0.2), /* Dark shadow */
      -5px -5px 10px rgba(255, 255, 255, 0.7); /* Light shadow for 3D effect */
    transition: all 0.3s ease-in-out;
		
  }

  .container:hover {
    box-shadow: 
      12px 12px 25px rgba(0, 0, 0, 0.3), 
      -6px -6px 12px rgba(255, 255, 255, 0.9);
    transform: translateY(-5px); /* Slight lift */
  }
  
  /* Header Section */
  .header {
    background-color: #0031ba;
    color: white;
    padding: 20px 0;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
    text-transform: uppercase;
  }
  
  /* Content Section */
  .content-box {
    background: #f3e5f5;
    color: #424242;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #e0e0e0;
    max-width: 800px;
    margin: 0 auto 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .content-box p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Footer Section */
  .footer-banner {
    background-color: #7e57c2;
    color: white;
    padding: 10px 0;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .footer-banner p {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
  }