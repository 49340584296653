.car-img-cont{
    display: flex;
}
.car-img{
    height: 20rem;
    margin: 0.6rem;
}

/*  */
.car-video{
    height: 100%;
    width: 100%;
}
.in-cont{
    height: 20rem;
    width: 100%;
    margin: 0.6rem;
    margin: 0 1.5rem;
    background-color: white;
    display: flex;
    justify-content: center;
}
@media (max-width:399px){
    .in-cont{
        height: 10rem;
        margin: 0 1.2rem;
    }
}