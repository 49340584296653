.click-ic-cont {
  margin: 0.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 155px;
  height: 155px;
  color: var(--primary-cl);
  position: relative;
  /*cursor: pointer;*/
  box-sizing: border-box;
}
.click-ic-cont-active {
  background-color: var(--primary-cl);
  color: white;
}
.item-click-ic {
  width: 70px;
  height: 75px;
  object-fit: fill;
}
.item-click-ic-active {
  filter: brightness(0) invert(1);
}
.text-click-ic {
  /* color: var(--primary-cl); */
  font-size: 1rem;
  margin: 1rem 0;
  text-align: center;
}

.show-box {
  height: 150px;
  background-color: red;
  top: 0;
  z-index: 999;
  width: 85vw;
  position: relative;
  margin: auto;
}

@media (max-width:500px){
  .click-ic-cont{
    height: 130px;
    width: 100px;
  }
}