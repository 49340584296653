.sub-title {
  font-weight: bold;
  color: white;
  padding: 0.2rem 0.3rem;
  margin: 0.2rem;
}
.mission-vision-box{
  background-color: #FA8128;

}

/* Awards and recognition */
.awardsCardHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* Ledaership */
.leadership-sub-title{
  padding: 1rem 1rem 0 1rem;
  font-size: 2rem;
}