.key-sec-images-cont {
  display: flex;
  flex-direction: column;
}

.small-img {
  width: 15rem;
  margin: 0.3rem;
  height: 9rem;
  background-size: contain;
}
.large-img {
  width: 30.6rem;
  margin: 0.3rem;
  height: 20rem;
  background-size: contain;
}
img{
    object-fit: cover;
}
@media (max-width: 500px) {
  .small-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .large-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .key-sec-images-cont{
    
    width: min-content;
  }
}
