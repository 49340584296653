.form-box {
  padding: 1rem;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
  
}
.form-box input{
    margin: 1rem ;
    height: 2rem;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100;
    
}
.input-name{
    width: 88%;
}
.form-submit-text{
    padding: 2rem ;
}

@media (max-width:400px){
    .input-name{
        width: auto;
    }
    .form-box{
        text-align: center;
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}