.avatar-img{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 1px solid gray;
    padding: 0.2rem;
    margin-right: 1rem;
    /* margin: 0.5rem; */
}
.t-card-p1{
    display: flex;
    padding: 1rem 0.4rem;
    justify-content: space-around;
    text-align: left;
    height: 6.5rem;
}
.t-card-p1-sub1 h5{
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
.t-card-p1-sub1 p{
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}
.t-card-p2{
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;

}
.extended{
height: 40rem;
}
.compressed{
    height: 22rem;
}
.fa-quote-left{
    font-size: 3rem;
    color: #FA8128;
    text-align: left !important;
    display: block !important;
}
.t-card-p2 .desc{
    font-size: 0.9rem;
    padding: 1rem;
    text-align: center;

}
.t-card-p2 .descShort{
    padding: 1rem;
    text-align: center;
    height: 13rem;
}
.read-more{
    text-align: center;
    color: #FA8128;
    cursor: pointer;
}

.t-card-p2-sub1{
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
