.sub-cont-key-sec-color-ic{
    /* background-color: whitesmoke; */
    margin: 1rem 0.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 180px;
    /* height: 155px; */
}
.item-color-ic{
    height: 90px;
    width: 90px;
    object-fit: fill;
}
.text-color-ic{
    font-size: 1rem;
    margin: 1rem 0;
    text-align: center;
}

@media (max-width: 500px){
    .sub-cont-key-sec-color-ic{
      /* height: 120px; */
      width: 120px;
    }
    .item-color-ic {
      width: 90px;
    }
  }
  