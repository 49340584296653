.bg-aboutus{
    background-image: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/about_us/team_banner.jpg');
    height: 95vh;
}
.hero-title-about-us {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 60px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: black; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 90px; 
    right: 5px;
  }
@media (max-width:400px) {
    .bg-aboutus{
        height: 45vh;
    }

    .hero-title-about-us {
        font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
        font-size: 20px; /* Adjust to match the size of the AI text */
        font-weight: bold; /* Make the font weight bold if needed */
        color: black; /* Match the color */
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
        padding: 5px;
        position: absolute;
        top: 90px; 
        right: 5px;
      }
}