/*  */
.wedotext-cont {
  background-color: #cbcbcb;
  padding: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.wedotext-sub-cont{
    max-width: 60%;
    margin: auto;
    text-align: center;
}

.wedotext-sub-cont p {
    font-size: 1.1rem;
    padding: 1.3rem;
    line-height: 1.6;
    text-align: justify;
}
.page-section-title-csr{
    text-align: left !important;
}
.know-more-a{
    text-decoration: none;
    display: flex;
    text-align: left;
}