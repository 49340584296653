.bg-about-us{
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1640584645/orion-new-website/Home_-_New_copy_bumhc0.png');
    height: 95vh;
}

@media (max-width:500px){
    .bg-about-us{
        height: 35vh;
    }   
}

.home {
    width: 100%;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bg-home{
    /*
    background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640324693/orion-new-website/main%20home/main-home-hero-banner_c0eghx.png");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/main-home-banner.png");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad/Studyabroad_1.png");
    */
    /*height: 60vh;*/
  }
  
  .bg-home-1{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_1.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg-home-2{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad.jpg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_2.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }

  .bg-home-3{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_jobs_abroad.jpeg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_1.png");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }

  .bg-home-4{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/oscg_ai_banner_2.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }
  
  .bg-home-ignou{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
    height: 80vh;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bg-home-ignou-2{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou2.jpeg");
    height: 80vh;
    background-size:contain;
    background-repeat: no-repeat;
  }
  
  .home-data-cont{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .home-data-subcont-right{
    color: white;
    width: 35%;
    padding: 5rem;
  }
  .hero-title-1{
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-align: left;
  }
  .hero-title-2{
    font-size: 3.5rem;
    line-height: 3.8rem;
    font-weight: 500;
    text-align: left;
  }
  .hero-title-extra{
    font-weight: 500;
    font-size: 2.5rem;
    margin: 1rem 0; 
  }
  .inner-cont{
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1100px) {
    .home-data-subcont-right{
      padding: 2.5rem;
    }
    .hero-title-1{
      font-size: 1.7rem;
      line-height: 2.1rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 3.2rem;
      line-height: 3.5rem;
      font-weight: 500;
    }
    
  }
  @media (max-width: 500px) {
    .home {
      height: 40vh;
      /* background-size: 100% 100%; */
      background-repeat: no-repeat;
      background-position: 0% 20%;
      background-position: center;
    }
    .home-data-subcont-right{
      padding: 0.4rem;
      width: 45%;
    }
    .hero-title-1{
      font-size: 0.9rem;
      line-height: 1rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
    .hero-title-extra{
      font-weight: 500;
      font-size: 0.9rem;
      margin: 0.5rem 0; 
    }
    .inner-cont{
      margin:1rem;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 850px){
    .home {
      height: 65vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .home-data-subcont-right{
      padding: 2rem;
      width: 38%;
    }
    .hero-title-1{
      font-size: 1.5rem;
      line-height: 1.9rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 2.6rem;
      line-height: 3.3rem;
      font-weight: 500;
    }
  }
  