.company-text-box {
  max-width: 60%;
  text-align: center;
}
.comp-text {
  margin: 1rem 0;
}
/*  */

.cont-dev-card-img-cont img {
  width: 100%;
  height: 18rem;
  object-fit: cover;
}
.cont-dev-card-sub-cont {
  padding: 1.5rem 3rem;
}

@media (max-width: 500px) {
  .cont-dev-card-img-cont img {
    height: 15rem;
  }
  .card-ls-abt-us{
    width: auto;
  }
}
