.buttons-cont {
  display: flex;
  /* background-color: red; */
  justify-content: space-around;
  width: 30rem;
  margin: auto;
}
.feedback-form-title {
  margin: auto;
  padding: 2rem;
  margin-top:1rem ;
  margin-bottom: -3rem;
}
.form-box-new {
  width: 36rem;
  min-height: 30rem;
}
.random-ptag {
  padding-left: 1rem;
}
.this-btn-cont {
  display: flex;
  justify-content: center;
}
.form-box-newest {
  width: 36rem;
  min-height: 30rem;
}

@media (max-width: 580px) {
  .buttons-cont {
    display: flex;
    flex-direction: column;
    height: 10rem;
    justify-content: space-around;
    width: auto;
    margin: auto;
  }
}
