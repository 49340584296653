.container-playStore {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.container-playStore p {
  font-size: 23px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.sub-cont-playstore {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  margin: 1.5rem;
}
.playstore-logo {
  height: 3rem;
  margin: 1.5rem;
  border: 1px solid #A2A2A2;
}
.playstore-input {
  height: 2.5rem;
  padding: 0.3rem;
  border-radius: 5px;
  border: none;
  padding-left: 1rem;
  width: 29rem;
  border: 1px solid #A2A2A2;
}
.playstore-button {
    background-color: var(--primary-cl);
    color: white;
    height: 3rem;
    border: none;
    border-radius: 5px;
    padding: 0 2rem;
    margin: 1.5rem;
    cursor: pointer;

}
.playstore-button:hover{
  background-color: var(--hover-cl);
}
