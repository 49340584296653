.contactus-sub-cont{
    display: flex;
    justify-content: space-evenly;
    background-color: whitesmoke;
    flex-wrap: wrap;
}

.contactus-boxs{
    margin: 2rem;
}
.contactus-boxs h3{
    font-size: 1.5rem;
    font-weight: 550;
    margin-bottom: 0.5rem;
}
.contactus-boxs p{
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.contactus-map{
    width: 100%;
}

