.focus-group-card{
    /* background-color:rgb(228, 225, 225); */
    background-color: white;
    text-align: center;
    height: 12.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.focus-group-card p{
    color: #FFAA46;
    font-size: 1.1rem;
    padding: 1.3rem;
}
.focus-group-sub-title{
    text-align: center;
    margin: 2rem 0.5rem;
    font-size: 1rem;
}