.hero-csr {
  width: 100%;
  height: 70vh;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640599569/orion-new-website/CSR_Banner_bbp3ka_copy_lclerk.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 100% 100%; */
  /* opacity: 0.8; */
}
.hero-csr .overlay-bg{
  height: 100%;
  width: 100%;
  /* background-color: rgba(59, 58, 58, 0.438); */
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.overlay-bg h1{
  font-size: 3.5rem;
  color: whitesmoke;
  text-align: center;
  font-style: italic;
}

@media (max-width: 500px) {
  .hero-csr {
    height: 35vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .overlay-bg h1{
    font-size: 2rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px) {
  .hero-csr {
    height: 50vh;
    /* background-size: 100% 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
