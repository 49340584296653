.bg-skill-dev{
    /*
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1640758016/orion-new-website/Banner_onhgxn.png');
    */
    /*background-image: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/Banner_skilldev.png');*/
    background-image: url('Banner_skilldev4.webp');
    height: 90vh;
}
.hero-title-999 {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 40px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: white; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 100px; 
    right: 5px;
  }
  .hero-title-9999 {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 40px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: white; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 150px; 
    right: 5px;
  }
@media (max-width: 500px){
    .bg-skill-dev{
        height: 40vh;
    }
}