.bg-int-school {
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1643004239/orion-new-website/Banner_copy_wknnfm.png");
  height: 95vh;
}

@media (max-width: 500px) {
  .bg-int-school {
    height: 30vh;
    background-position: 42% 50%;
  }
}
