@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Josefin Sans", sans-serif;
}
:root {
  --primary-cl: #0031ba;
  --hover-cl: #ffaa46;
  --section-title-cl: #2f4152;
}
.App {
  height: 100vh;
}
.page-title {
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
}
.page-title h1 {
  padding: 0.5rem;
}

.testimonials-card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.kno-more-btnn-cont {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
/* Utils */
.fx {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.full-img {
  max-width: 100%;
  max-height: 100%;
}
/* Design Sys */

.section-container {
  padding: 4rem 0;
}
.section-title-cont {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
}
.section-title-line {
  width: 10rem;
  height: 4px;
  background-color: #b7b7b7;
  border: 0;
  margin-right: 1.5rem;
}
.section-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  color: #2f4152;
  color: var(--section-title-cl);
  max-width: 55%;
}
.p-text {
  color: #596875;
  font-size: 1rem;
  line-height: 1.2rem;
}
.p-text-small {
  color: #596875;
  font-size: .79rem;
  line-height: 1.2rem;
  text-align: center;
}
.card-sub-title {
  color: #2f4152;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.card-sub-title-small {
  color: #2f4152;
  font-size: .79rem;
  line-height: 1.4rem;
  text-align: center;
}
.card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 85%;
  margin: auto;
  /* background-color: yellow; */
}
.card-holder-full {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  /* background-color: yellow; */
}
.card {
  width: 15rem;
  margin: 0.6rem;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
}
.only-card {
  margin: 0.6rem;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
}
.card-landscape {
  width: 30rem;
  margin: 0.6rem;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
}
.card-bg-gray {
  background-color: #e8e8e8;
}
.bg-gray-lite {
  background-color: #fafafa;
}
.bg-apna-blue {
  background-color: #0031ba;
  background-color: var(--primary-cl);
  color: white;
}
@media (max-width: 580px) {
  .card-landscape {
    width: 15rem;
    margin: 0.6rem;
  }
  .section-title-line {
    width: 6rem;
  }
}

.one-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.one-view-text-box {
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: rgb(24, 23, 23);
  margin: 1rem;
}
.banner-over-text {
  font-size: 3rem;
}

/* //Utility */
.cl-white {
  color: white !important;
}
.bg-whsmoke {
  background-color: whitesmoke;
}
.bg-apna-orange {
  background-color: #fa8128;
}

.cl-orng-text {
  color: #ffaa46;
}

nav{
    height: 6rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    border-bottom: 2px solid whitesmoke;
    width: 75%;
    margin: auto;
}
.nav-cont{
    height:50px;   
}

.nav-items-cont{
    width: 70%;
}

.nav-items-list{
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-item{
    padding: 13px 5px;
    margin: 5px;
    font-size: 0.9rem;

}

.nav-item a{
    text-decoration: none;
    color: #000;
    
}
.nav-btn{
    padding: 5px;
    margin: 5px;
    background-color: #FA8128;
}

.menu-btn{
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
    display: none;
    font-size: 1.2rem;
}

@media(max-width: 500px){
    nav{
        padding: 0 1rem;
    }
    .nav-items-cont{
        display: none;
    }
    .menu-btn{
        display: inline;
        display: initial;
    }
}
@media screen and (min-width: 500px) and (max-width: 850px){
    .tab-hide{
        display: none;
    }
    nav{
        padding: 0 1rem;
    }
}

/* DisplayHoverItem */
.sub-menu{
    position: absolute;
    top: 70px;
    background-color: white;
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 99;
}
.active{
    display: flex !important;
}
.sub-menu a{
    text-decoration: none;
    color: black;
}
.sub-menu-item{
    padding: 5px;
    margin:  5px 13px;
    white-space:nowrap;
}
.sub-menu-item-bold{
    padding: 5px;
    margin:  5px 13px;
    font-weight: bold;
}
.nested-sub-menu{
    position: relative;
    background-color: rgba(246, 241, 241);
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 100;
    margin-top: 10px;
}
.nested-sub-menu-item{
    white-space:nowrap;
    display: flex;
    flex-direction: row;
}
.button {
  border-radius: 10px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  min-width: 7rem;
  height: 2.3rem;
  font-size: 0.9rem;
}

.login-btn {
  font-weight: 900;
}
.large-btn {
  /* height: 100%; */
  height: auto;
  width: auto;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-weight: 600;
}
.orange {
  color: #fff;
  background-color: var(--primary-cl);
}
.orange:hover {
  background-color: var(--hover-cl);
}

.white {
  color: var(--primary-cl);
  background-color: #fff;
  border: 1px solid var(--primary-cl);
}
.white:hover {
  color: var(--hover-cl);
  border: 1px solid var(--hover-cl);
}

@media (max-width: 460px) {
  /* .button {
    width: 6rem;
  } */
  .large-btn {
    width: 11rem !important;
    padding: 1rem 1rem !important;
    font-size: 1.2rem;
  }
}

/* Know more */
.kmb-cont {
  color: var(--primary-cl) !important;
  margin: auto;
}
.kmb-cont:hover {
  color: var(--hover-cl);
}
.button-know-more {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  color: var(--primary-cl) !important;
}
.button-know-more:hover {
  color: var(--hover-cl) !important;
}
.know-more-line-short {
  display: inline-block;
  width: 3rem;
  transition: 0.5s;
  background-color: var(--primary-cl) ;
  height: 1px;
  border: none;
}
.know-more-line-long {
  display: inline-block;
  width: 6rem;
  transition: 0.5s;
  background-color: var(--hover-cl) !important;
  height: 1px;
  border: none;
}


/* hero button */

.button-know-more-hero {
  color: white !important;
  
}
.button-know-more-hero:hover {
  color: var(--hover-cl) !important;
}
.know-more-line-short-hero {
  background-color: white !important;
}
.know-more-line-long-hero {
  background-color: var(--hover-cl) !important;
}

@media (max-width:500px){
  .hide{
    display: none;
  }
}
.focus-area-card{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
}
.focus-area-sub-cont1{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.focus-area-img{
    height: 100%;
    width: auto;
}
.focus-area-sub-cont2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0;
}
.fac-sd1{  
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.width60p{
    height: 60%;
}
.fac-sd2{
    height: 30%;
    margin: 0 2rem;
}
.card-sub-title-big{
    margin: 3rem 0;
    font-size: 1.8rem;
    line-height: 2.3rem;
}

@media (max-width:600px){
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 17rem;
        width: 100%;
        border-radius: 20px;
        object-fit: cover;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
    .card-sub-title-big{
        margin: 1rem 0;
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
}



.focus-area-card-slim{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    margin: 0.6rem;
    width: 11.3rem;
    
}


.focus-area-img-slim{
    width: 100%;
    height: 17rem;
    object-fit: cover;
}
.focus-area-sub-cont2-slim{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.fac-sd1-slim{
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.card-sub-title-sub{
    color: #2F4152 ;
    margin-top: 0.2rem;
}


/* @media (max-width:600px){
    
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 17rem;
        width: 100%;
        border-radius: 20px;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
} */
@media (max-width:600px){
    .focus-area-card-slim{
        width: 9.5rem;
        margin: 0.4rem;
        height: 22rem ;
    }
    .focus-area-img-slim{
        height: 14rem;
    }
    .slim-title{
        font-size: 0.68rem;
        line-height: 1rem;
    }
    .slim-name{
        font-size: 0.68rem;
        line-height: 1.1rem;
    }
    .focus-area-sub-cont2-slim{
        height: auto;
    }
}


.home {
  width: 100%;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-home{
  /*
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640324693/orion-new-website/main%20home/main-home-hero-banner_c0eghx.png");
  */
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/main-home-banner.png");
  height: 80vh;
  background-size:contain;
  /*
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  */
  background-repeat: no-repeat;
  /*
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad/Studyabroad_1.png");
  */
  /*height: 60vh;*/
}
.bg-home-1{
  /*
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
  */
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_1.jpg");
  height: 80vh;
  background-size:contain; 
  /*
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  */
  background-position: center;
  background-repeat: no-repeat;
}
.bg-home-2{
  /*
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad.jpg");
  */
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_2.jpg");
  height: 80vh;
  background-size:contain;
  /*
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  */
  background-repeat: no-repeat;
}

.bg-home-3{
  /*
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_jobs_abroad.jpeg");
  */
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_1.png");
  height: 80vh;
  background-size:contain;
  /*
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  */
  background-repeat: no-repeat;
}

.bg-home-4{
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/oscg_ai_banner_2.jpg");
  height: 80vh;
  background-size:contain;
  /*
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  */
  background-repeat: no-repeat;
}

.bg-home-ignou{
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
  height: 80vh;
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-home-ignou-2{
  background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou2.jpeg");
  height: 80vh;
  background-size:contain;
  background-repeat: no-repeat;
}

.home-data-cont{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-data-subcont-right{
  color: white;
  width: 35%;
  padding: 5rem;
}
.hero-title-1{
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
  text-align: left;
}
.hero-title-2{
  font-size: 3.5rem;
  line-height: 3.8rem;
  font-weight: 500;
  text-align: left;
}
.hero-title-extra{
  font-weight: 500;
  font-size: 2.5rem;
  margin: 1rem 0; 
}
.inner-cont{
  margin-bottom: 2.5rem;
}
@media (max-width: 1100px) {
  .home-data-subcont-right{
    padding: 2.5rem;
  }
  .hero-title-1{
    font-size: 1.7rem;
    line-height: 2.1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 3.2rem;
    line-height: 3.5rem;
    font-weight: 500;
  }
  
}
@media (max-width: 500px) {
  .home {
    height: 40vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 0.4rem;
    width: 45%;
  }
  .hero-title-1{
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .hero-title-extra{
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0.5rem 0; 
  }
  .inner-cont{
    margin:1rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px){
  .home {
    height: 65vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .home-data-subcont-right{
    padding: 2rem;
    width: 38%;
  }
  .hero-title-1{
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
  }
  .hero-title-2{
    font-size: 2.6rem;
    line-height: 3.3rem;
    font-weight: 500;
  }
}

/* .focus-area-img-cont {
  background-size: cover;
  background-position: center;
  height: 435px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 1s;
  width: 15rem;
  margin: 0.6rem;
}
.img3{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1639248601/lms-app/new-lms-website/Focus%20Area/Home_Skilling_xkax7w.png");

}
.img2{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1639248611/lms-app/new-lms-website/Focus%20Area/Home_Foreign_Internship_wlzizu.png");
}
.img1{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1639248605/lms-app/new-lms-website/Focus%20Area/Home_Staffing_gjxric.png");
}
.img4{
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1639248608/lms-app/new-lms-website/Focus%20Area/Home_Study_Abroad_jtwv9z.png");
}
.focus-area-img-cont .card-title{
    color: white;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 3rem;
}

.hover-card{
    background-color: rgba(0,0,0,0.7) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    position: relative;
    transition: 1s;
}
.hover-card-desc{
    padding: 1rem 2rem;
    text-align: center;
    color: white;
}
.hover-card-title{
    font-size: 1.5rem;
    color: white;
}
.hover-card-know{
    font-size: 1.3rem;
    position: relative;
    top: 7rem;
    color: #FA8128 !important;
} */


.playStoreLargeButton{
    background-color: var(--primary-cl);
    /* background-color: red; */
    width: 15rem;
    border-radius: 10px;
    font-weight: 400;
    outline: none;
    border: none;
    cursor: pointer;
    height: 2.3rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: white;
    margin: auto;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  
  .playStoreLargeButton:hover{
    background-color: var(--hover-cl);
  }

  .playStoreBtnCont{
      margin:  2rem 0;
  }
.course-card-cont {
  height: 24.5rem;
  background-color: white;
}
.course-card-img {
  width: 15rem;
  height:10rem;
}
.course-card-name{
    /* Indranil: 09Dec24: Fixed padding to solve Firefox padding issue - removed '0.9rem'
    /*padding: 0.5rem 0.9rem;*/
    padding: 0.5rem;
    padding-top: 1.5rem;
    font-size: 1.3rem;
    height: 3.5rem;
    line-height: 1.8rem;
}
.course-card-desc{
    padding: 0.5rem 0.9rem;
    height: 7rem;
    /* color: #5E5E5E; */
    font-size: 1rem;
    line-height: 1.2rem;
}
.button-cont{
    display: flex;
    justify-content: space-evenly;
    margin: 0.5rem 0.9rem;
}
.sub-check{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media(max-width:460px){
  .course-card-cont {
    /*width: 15rem; */
    height: 25rem;
  }
  .course-card-desc{
    font-size: 0.8rem;
    height: 5.5rem;
  }
  .course-card-name{
    padding: 0.5rem 1.2rem;
    padding-top: 1rem;
    font-size: 1.5rem;
    height: 2.5rem;
}
}

.sub-cont{
    /* background-color: whitesmoke; */
    margin: 0.6rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 155px;
    height: 155px;
}
.item{
    width: 100px;
    /*filter: grayscale(100%) ;*/
    opacity: 0.8;
}

@media (max-width: 500px){
    .card-holder-clients{
      width: 100%;
    }
    .sub-cont{
      height: 120px;
      width: 120px;
    }
    .item {
      width: 90px;
    }
  }
  
.our-success-card-holder{
    display: flex;
    flex-wrap: wrap;
}
.our-success-numbers-card{
    width: 20rem;
    margin: 0.6rem;
    /* background-color: red; */
    text-align: center;
    margin-bottom: 3.5rem;
}
.our-s-number{
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.our-s-name{
    font-size: 1rem;
}
.key-sec-images-cont{
display: flex;
flex-direction: column;
}
.dest-img-cont{
    /* position: relative; */
}
.small-img{
    width: 15rem;
    margin: 0.3rem;
    height: 9rem;
    background-size: contain;
    
}
.large-img{
    width: 30.6rem;
    margin:0.3rem;
    height:20rem;
    background-size: contain;
}

@media (max-width:500px){
    .small-img{
        width: 7.5rem;
        margin: 0.3rem;
    }
    .large-img{
        width: 15.3rem;
        margin:0.3rem
    }
}

/* section one small icons bule */
.sub-card-blue-ic{
    margin-bottom: 3rem;
}

.sub-cont-key-sec-blue-ic{
    /* background-color: whitesmoke; */
    margin: 0.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* align-content: center;
    justify-content: center; */
    width: 155px;
    height: 155px;
}
.item-blue-ic{
    width: 70px;
    height: 75px;
    object-fit: fill;
}
.text-blue-ic{
    color: var(--primary-cl);
    font-size: 1rem;
    margin: 1rem 0;
    text-align: center;
}

@media (max-width: 500px){
    .card-holder-clients{
      width: 100%;
    }
    .sub-cont-key-sec-blue-ic{
      height: 120px;
      width: 120px;
    }
    .item-blue-ic {
      width: 90px;
    }
  }
  
.container-playStore {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.container-playStore p {
  font-size: 23px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.sub-cont-playstore {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  margin: 1.5rem;
}
.playstore-logo {
  height: 3rem;
  margin: 1.5rem;
  border: 1px solid #A2A2A2;
}
.playstore-input {
  height: 2.5rem;
  padding: 0.3rem;
  border-radius: 5px;
  border: none;
  padding-left: 1rem;
  width: 29rem;
  border: 1px solid #A2A2A2;
}
.playstore-button {
    background-color: var(--primary-cl);
    color: white;
    height: 3rem;
    border: none;
    border-radius: 5px;
    padding: 0 2rem;
    margin: 1.5rem;
    cursor: pointer;

}
.playstore-button:hover{
  background-color: var(--hover-cl);
}

.button {
  border-radius: 10px;
  font-weight: 400;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  min-width: 7rem;
  height: 2.3rem;
  font-size: 0.9rem;
}
.login-btn {
  font-weight: 900;
}
.large-btn {
  /* height: 100%; */
  height: auto;
  width: auto;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-weight: 600;
}
.orange {
  color: #fff;
  background-color: var(--primary-cl);
}
.orange:hover {
  background-color: var(--hover-cl);
}

.white {
  color: var(--primary-cl);
  background-color: #fff;
  border: 1px solid var(--primary-cl);
}
.white:hover {
  color: var(--hover-cl);
  border: 1px solid var(--hover-cl);
}

@media (max-width: 460px) {
  /* .button {
    width: 6rem;
  } */
  .large-btn {
    width: 11rem !important;
    padding: 1rem 1rem !important;
    font-size: 1.2rem;
  }
}

/* Know more */
.kmb-cont {
  color: var(--primary-cl) !important;
  margin: auto;
}
.kmb-cont:hover {
  color: var(--hover-cl);
}
.button-know-more {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  color: var(--primary-cl) !important;
}
.button-know-more:hover {
  color: var(--hover-cl) !important;
}
.know-more-line-short {
  display: inline-block;
  width: 3rem;
  transition: 0.5s;
  background-color: var(--primary-cl) ;
  height: 1px;
  border: none;
}
.know-more-line-long {
  display: inline-block;
  width: 6rem;
  transition: 0.5s;
  background-color: var(--hover-cl) !important;
  height: 1px;
  border: none;
}


/* hero button */

.button-know-more-hero {
  color: white !important;
  
}
.button-know-more-hero:hover {
  color: var(--hover-cl) !important;
}
.know-more-line-short-hero {
  background-color: white !important;
}
.know-more-line-long-hero {
  background-color: var(--hover-cl) !important;
}

@media (max-width:500px){
  .hide{
    display: none;
  }
}
.bg-gray{
    background-color: whitesmoke;
}
.footer-bar {
  background-color: var(--primary-cl);
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  padding: 0.6rem;
  border-top: 1px solid gray;
}
.footer-bar-cont{
  background-color: var(--primary-cl);
  color: white;
}
.flex2 {
  display: flex;
}
.flex2 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.flex1 p {
  padding: 0.4rem;
  font-size: 0.9rem;
}
.footer-box a{
  text-decoration: none;
  color: white;
}
@media (max-width: 460px) {
  .footer-bar {
    flex-direction: column;
  }

  .flex1 p {
    /* font-size: 0.7rem; */
    text-align: center;
  }

  .flex2 p {
    flex-wrap: wrap;
    font-size: 0.6rem;
  }
}
/* ///////////////////////////////////////////////////////// */
.footer-logo {
  width: 9.5rem;
  margin: 2rem 0;
}
.logo-skill-india{
    width: 6rem;
    padding: 0.5rem;
    padding-left: 0;
    object-fit: contain;
}
.logo-nsdc{
    width: 9rem;
    padding: 1rem;
    object-fit: contain;
}
.logo-pstore{
  object-fit: contain;
  width: 8rem;
}
.logo-contn{
  display: flex;

}
.sub-content-box-icons{
    margin-bottom: 2.8rem;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
}
.fab{
    font-size: 1.2rem;
    padding: 1rem 1rem 0 1rem;
}
.fab-special{
    padding-left: 0 !important;
}
footer {
  display: flex;
  padding: 2rem 1rem 1.5rem 3rem;
  background-color: whitesmoke;
  height: 100%;
  border-top: 1px solid gray;
  background-color: var(--primary-cl);
  color: white !important;
}

.box-1 {
  width: 30%;
  /* background-color: red; */
}
.box-2 {
  width: 23%;
}
.box-3 {
  width: 23%;
}
.box-4 {
  width: 23%;
}

.sub-content-box {
  margin: 2.5rem 0;
}
.sub-content-box p {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}
.sub-content-box h5 {
  padding-bottom: 0.5rem;
  font-size: 1rem;
}
p a{
  text-decoration: none;
  color:  white;
}

@media (max-width:1027px) {
  .box-1 {
    width: 100%;
  }
  .box-2 {
    width: 100%;
  }
  .box-3 {
    width: 100%;
  }
  .box-4 {
    width: 100%;
  }
  footer{
    display: flex;
    flex-wrap: wrap;
  }
}
.mobile-nav{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    top: 5rem;
    left: -120%;
    transition: ease-in 0.2s;
    z-index: 999;
}

.mobile-nav.active{
    left: 0;
}

.mobile-nav-items{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 3rem;
}

.mobile-nav-items-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
}
.mobile-nav-items-list li{
    font-size: 1.3rem;
    font-weight: 500;
}
.nav-item-icon-cont{
    display: flex;
    justify-content: space-between;
}
.nav-item-m{
    padding: 6px 5px;
    margin: 5px;
}
.nav-item-m a{
    text-decoration: none;
    color: #000;
}


/* pp */
.mob-sub-menu{
    background-color: white;
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 99;
}
.mob-active{
    display: flex !important;
}
.mob-sub-menu a{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
}
.mob-sub-menu-item{
    padding: 5px;
    margin:  5px 13px;
    
}

.mob-sub-menu-item-bold{
    padding: 5px;
    margin:  5px 13px;
    font-weight: bold;
}
.nested-mob-sub-menu-item{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}
.one-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 ;
}

.one-view .text-data{
    padding: 0.7rem;
}
.one-view-sub-p{
    font-size: 1.1rem;
    line-height: 1.5rem;
    /* color: rgb(24, 23, 23); */
}

.one-view-sub h4{
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 1rem;
}
.one-view-sub{
    margin: 1.2rem;
}

.sub-title {
  font-weight: bold;
  color: white;
  padding: 0.2rem 0.3rem;
  margin: 0.2rem;
}
.mission-vision-box{
  background-color: #FA8128;

}

/* Awards and recognition */
.awardsCardHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* Ledaership */
.leadership-sub-title{
  padding: 1rem 1rem 0 1rem;
  font-size: 2rem;
}
.awards-card {
  background-color: var(--primary-cl);
  color: white;
}
.awards-card .award-img {
  height: 17rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}
.award-img img{
  width: 100%;
  object-fit: cover;
}
.awards-card .awards-card-desc {
  padding: 1.5rem;
  line-height: 1.3rem;
  font-size: 1rem;
  align-content: center;
  align-items: center;
}
.name-post{
  text-align: center;
  margin-top: 0.5rem;
}
.name-post-h5{
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.name-post-p{
  font-size: 0.8rem;
}
@media (max-width: 435px) {
  .awards-card {
    width: 17rem !important;
    height: auto !important;
  }
  .awards-card .award-img {
    height: 14rem !important;
    width: 17rem !important;
  }
  .awards-card img {
    height: 14rem !important;
    width: 17rem !important;
  }
}

.bg-aboutus{
    background-image: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/about_us/team_banner.jpg');
    height: 95vh;
}
.hero-title-about-us {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 60px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: black; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 90px; 
    right: 5px;
  }
@media (max-width:400px) {
    .bg-aboutus{
        height: 45vh;
    }

    .hero-title-about-us {
        font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
        font-size: 20px; /* Adjust to match the size of the AI text */
        font-weight: bold; /* Make the font weight bold if needed */
        color: black; /* Match the color */
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
        padding: 5px;
        position: absolute;
        top: 90px; 
        right: 5px;
      }
}
.company-text-box{
    max-width: 60%;
    text-align: center;
}
.comp-text{
    margin: 1rem 0;
}
.wide-text-card {
  box-shadow: #00000040 0px 8px 25px;
  border-radius: 20px;
  padding: 3rem;
  box-sizing: border-box;

}
.cont-sub-com-prof {
  margin-bottom: 4rem;
}
.wc-title {
  margin: 1rem 0;
}
@media (max-width: 500px) {
  .wide-text-card {
      text-align: center;
    margin: 1rem;
    padding: 2rem;
    box-sizing: content-box;
    font-size: 1rem !important;
  }
}

.focus-area-card{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
    overflow: hidden;
    background-color: white;
}
.focus-area-sub-cont1{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.focus-area-img{
    height: 100%;
    width: auto;
}
.focus-area-sub-cont2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0;
}
.fac-sd1{
    /* height: 60%; */
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.fac-sd2{
    height: 30%;
    margin: 0 2rem;
}
.card-sub-title-sub{
    color: #2F4152 ;
    margin-top: 0.2rem;
}


@media (max-width:600px){
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 22rem;
        width: 100%;
        border-radius: 20px;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
}

@media (max-width:400px){
    .card-holder-team{
        width: 100%;
    }
}


.bg-titp{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/career-banner.jpg");
    height: 60vh;
}
.hero-title-nats {
  font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
  font-size: 60px; /* Adjust to match the size of the AI text */
  font-weight: bold; /* Make the font weight bold if needed */
  color: white; /* Match the color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
  padding: 5px;
  position: absolute;
  top: 120px; 
  right: 5px;
}

@media (max-width: 500px) {
    .bg-titp {
      height: 30vh;
      background-position: center;
      background-position: 35% 0;
    } 
    .titp-home-data-subcont-right{
      width: 25%;
      margin-top: 1.5rem;
    }

    .hero-title-nats {
      font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
      font-size: 10px; /* Adjust to match the size of the AI text */
      font-weight: bold; /* Make the font weight bold if needed */
      color: white; /* Match the color */
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
      padding: 5px;
      position: absolute;
      top: 100px; 
      right: 5px;
    }

    
  }
  @media screen and (min-width: 500px) and (max-width: 850px){
    .bg-titp {
      height: 50vh;
      background-position: center;
    }

    .hero-title-nats {
      font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
      font-size: 10px; /* Adjust to match the size of the AI text */
      font-weight: bold; /* Make the font weight bold if needed */
      color: white; /* Match the color */
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
      padding: 5px;
      position: absolute;
      top: 100px; 
      right: 5px;
    }
  }

  


















  .faq-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  
  
  .faq-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #003366;
    margin-bottom: 30px;
  }
  
  .faq-item {
    border: 1px solid #003366;
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #6f98ce;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .faq-arrow {
    color: whitesmoke;
    font-size: 18px;
    transition: transform 0.3s;
  }
  
  /*.faq-answer {
    font-size: 16px;
    color: #555555;
    line-height: 1.6;
    padding: 15px;
    display: none;   }
  
  .faq-item.active .faq-answer {
    display: block;
  }*/
  
  .faq-item.activee .faq-arrow {
    transform: rotate(90deg); /* Rotate arrow when active */
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .faq-answer {
    font-size: 17px;
    font-weight: bold;
    color: #691902;
    line-height: 1.6;
    padding: 15px;
    opacity: 0; /* Hidden by default */
    transform: translateX(-100%); /* Off-screen by default */
    transition: opacity 0.3s, transform 0.3s; /* Fallback for smooth state changes */
  }
  
  .faq-item.activee .faq-answer {
    display: block;
    opacity: 1; /* Fade in */
    transform: translateX(0); /* Move into view */
    animation: slideInLeft 0.9s ease; /* Apply animation */
    color: #691902;
  }





  .faq-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background-size: cover;
    position: relative;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    color: #ffffff; /* Ensure text stands out */
    font-family: Arial, sans-serif;
  }
  
  /* Overlay for reducing background image opacity */
  .faq-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.5); */
    background: inherit;
    z-index: -1;

    filter        : blur(10px);
    -moz-filter   : blur(10px);
    -webkit-filter: blur(10px);
    -o-filter     : blur(10px);

    transition        : all 2s linear;
    -moz-transition   : all 2s linear;
    -webkit-transition: all 2s linear;
    -o-transition     : all 2s linear;
    border-radius: 10px;
    z-index: 1;
  }
  
  /* Ensure text is above overlay */
  .faq-container * {
    position: relative;
    z-index: 2;
  }


  /* Individual container-specific background images */
.faq-container1 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  
}

.faq-container2 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
}

.faq-container3 {
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center;
}





.faq-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  background-size: cover;
  box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
  
}

.faq-container:hover {
  
  background: #FFF;
  background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background.jpg') no-repeat center center; /* Replace with the correct path */
  background-size: cover;
  border: 1px solid #d27803;
        /*box-shadow: 0 0 10px #81D4FA;*/
  box-shadow: 3px 3px 0px 5px #691902;
        transition: all ease-in .3s;
}





  .faq-container2 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    
  }
  

  .faq-container2:hover {
    
    background: #FFF;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background2.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
		border: 1px solid #d27803;
        	/*box-shadow: 0 0 10px #81D4FA;*/
		box-shadow: 3px 3px 0px 5px #f4400e;
        	transition: all ease-in .3s;
  }

  .faq-question2 {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #0031ba;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .faq-container3 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    color: #ffffff; /* Text is white for contrast */
    font-family: Arial, sans-serif;
  }
  
  /* Dark overlay on background */
  .faq-container3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(150, 148, 148, 0.5); /* Semi-transparent black */
    border-radius: 10px;
    z-index: 0.5;
  }
  
  /* Ensure text is above overlay */
  .faq-container3 * {
    position: relative;
    z-index: 2;
  }


  
  .faq-container31 {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
    box-shadow: 3px 3px 0px 5px rgba(0, 0, 0, 0.1);
    
    
  }

  .faq-container3:hover {
    
    background: #FFF;
    background: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nats/faq_background3.jpg') no-repeat center center; /* Replace with the correct path */
    background-size: cover;
		border: 1px solid #1904ba;
        	/*box-shadow: 0 0 10px #81D4FA;*/
		box-shadow: 3px 3px 0px 5px #1904ba;
        	transition: all 0.3s ease-in-out;
  }

  .faq-question3 {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
    background: #7e57c2;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }




  .container {
    text-align: center;
    padding: 20px;
    margin-bottom: 50px;
    background: #FFF;
    
    background: linear-gradient(to bottom right, #ffffff, #f3f3f3); /* Subtle gradient */
    border: 1px solid #e0e0e0; /* Light border */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 
      10px 10px 20px rgba(0, 0, 0, 0.2), /* Dark shadow */
      -5px -5px 10px rgba(255, 255, 255, 0.7); /* Light shadow for 3D effect */
    transition: all 0.3s ease-in-out;
		
  }

  .container:hover {
    box-shadow: 
      12px 12px 25px rgba(0, 0, 0, 0.3), 
      -6px -6px 12px rgba(255, 255, 255, 0.9);
    transform: translateY(-5px); /* Slight lift */
  }
  
  /* Header Section */
  .header {
    background-color: #0031ba;
    color: white;
    padding: 20px 0;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
    text-transform: uppercase;
  }
  
  /* Content Section */
  .content-box {
    background: #f3e5f5;
    color: #424242;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #e0e0e0;
    max-width: 800px;
    margin: 0 auto 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .content-box p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Footer Section */
  .footer-banner {
    background-color: #7e57c2;
    color: white;
    padding: 10px 0;
    border-radius: 10px;
    margin-top: 20px;
  }
  
  .footer-banner p {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
  }
.focus-group-card{
    /* background-color:rgb(228, 225, 225); */
    background-color: white;
    text-align: center;
    height: 12.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.focus-group-card p{
    color: #FFAA46;
    font-size: 1.1rem;
    padding: 1.3rem;
}
.focus-group-sub-title{
    text-align: center;
    margin: 2rem 0.5rem;
    font-size: 1rem;
}
/*  */
.wedotext-cont {
  background-color: #cbcbcb;
  padding: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.wedotext-sub-cont{
    max-width: 60%;
    margin: auto;
    text-align: center;
}

.wedotext-sub-cont p {
    font-size: 1.1rem;
    padding: 1.3rem;
    line-height: 1.6;
    text-align: justify;
}
.page-section-title-csr{
    text-align: left !important;
}
.know-more-a{
    text-decoration: none;
    display: flex;
    text-align: left;
}
.sub-cont-key-sec-color-ic{
    /* background-color: whitesmoke; */
    margin: 1rem 0.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 180px;
    /* height: 155px; */
}
.item-color-ic{
    height: 90px;
    width: 90px;
    object-fit: fill;
}
.text-color-ic{
    font-size: 1rem;
    margin: 1rem 0;
    text-align: center;
}

@media (max-width: 500px){
    .sub-cont-key-sec-color-ic{
      /* height: 120px; */
      width: 120px;
    }
    .item-color-ic {
      width: 90px;
    }
  }
  
.section-small-title{
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3.5rem;
}
@media (max-width:500px){
    .section-small-title{
        margin: 1.5rem;
    }   
}

.bg-skill-dev{
    /*
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1640758016/orion-new-website/Banner_onhgxn.png');
    */
    /*background-image: url('https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/Banner_skilldev.png');*/
    background-image: url(/static/media/Banner_skilldev4.05780816.webp);
    height: 90vh;
}
.hero-title-999 {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 40px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: white; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 100px; 
    right: 5px;
  }
  .hero-title-9999 {
    font-family: 'YourIdentifiedFont', sans-serif; /* Replace with the matched font */
    font-size: 40px; /* Adjust to match the size of the AI text */
    font-weight: bold; /* Make the font weight bold if needed */
    color: white; /* Match the color */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
    padding: 5px;
    position: absolute;
    top: 150px; 
    right: 5px;
  }
@media (max-width: 500px){
    .bg-skill-dev{
        height: 40vh;
    }
}
.click-ic-cont {
  margin: 0.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 155px;
  height: 155px;
  color: var(--primary-cl);
  position: relative;
  /*cursor: pointer;*/
  box-sizing: border-box;
}
.click-ic-cont-active {
  background-color: var(--primary-cl);
  color: white;
}
.item-click-ic {
  width: 70px;
  height: 75px;
  object-fit: fill;
}
.item-click-ic-active {
  filter: brightness(0) invert(1);
}
.text-click-ic {
  /* color: var(--primary-cl); */
  font-size: 1rem;
  margin: 1rem 0;
  text-align: center;
}

.show-box {
  height: 150px;
  background-color: red;
  top: 0;
  z-index: 999;
  width: 85vw;
  position: relative;
  margin: auto;
}

@media (max-width:500px){
  .click-ic-cont{
    height: 130px;
    width: 100px;
  }
}

.bg-int-school {
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1643004239/orion-new-website/Banner_copy_wknnfm.png");
  height: 95vh;
}

@media (max-width: 500px) {
  .bg-int-school {
    height: 30vh;
    background-position: 42% 50%;
  }
}



.car-img-cont{
    display: flex;
}
.car-img{
    height: 20rem;
    margin: 0.6rem;
}

/*  */
.car-video{
    height: 100%;
    width: 100%;
}
.in-cont{
    height: 20rem;
    width: 100%;
    margin: 0.6rem;
    margin: 0 1.5rem;
    background-color: white;
    display: flex;
    justify-content: center;
}
@media (max-width:399px){
    .in-cont{
        height: 10rem;
        margin: 0 1.2rem;
    }
}
.key-sec-images-cont {
  display: flex;
  flex-direction: column;
}

.small-img {
  width: 15rem;
  margin: 0.3rem;
  height: 9rem;
  background-size: contain;
}
.large-img {
  width: 30.6rem;
  margin: 0.3rem;
  height: 20rem;
  background-size: contain;
}
img{
    object-fit: cover;
}
@media (max-width: 500px) {
  .small-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .large-img {
    width: 15rem;
    margin: 0.3rem;
    height: auto;
  }
  .key-sec-images-cont{
    
    width: -webkit-min-content;
    
    width: min-content;
  }
}


.dest-img-cont{
    position: relative;
    background-color: black;
    margin: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.destination-title{
    color: white;
    font-size: 1.7rem;
}

.dest-img-cont img{
    opacity: 0.6;
    margin: 0 !important;
    padding: 0 !important;
}



.form-box {
  padding: 1rem;
  border-radius: 20px;
  box-shadow: #00000040 0 8px 25px;
  
}
.form-box input{
    margin: 1rem ;
    height: 2rem;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100;
    
}
.input-name{
    width: 88%;
}
.form-submit-text{
    padding: 2rem ;
}

@media (max-width:400px){
    .input-name{
        width: auto;
    }
    .form-box{
        text-align: center;
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.avatar-img{
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 1px solid gray;
    padding: 0.2rem;
    margin-right: 1rem;
    /* margin: 0.5rem; */
}
.t-card-p1{
    display: flex;
    padding: 1rem 0.4rem;
    justify-content: space-around;
    text-align: left;
    height: 6.5rem;
}
.t-card-p1-sub1 h5{
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
.t-card-p1-sub1 p{
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}
.t-card-p2{
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;

}
.extended{
height: 40rem;
}
.compressed{
    height: 22rem;
}
.fa-quote-left{
    font-size: 3rem;
    color: #FA8128;
    text-align: left !important;
    display: block !important;
}
.t-card-p2 .desc{
    font-size: 0.9rem;
    padding: 1rem;
    text-align: center;

}
.t-card-p2 .descShort{
    padding: 1rem;
    text-align: center;
    height: 13rem;
}
.read-more{
    text-align: center;
    color: #FA8128;
    cursor: pointer;
}

.t-card-p2-sub1{
    display: flex;
    flex-direction: column;
    align-content: space-around;
}

.hero-csr {
  width: 100%;
  height: 70vh;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640599569/orion-new-website/CSR_Banner_bbp3ka_copy_lclerk.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 100% 100%; */
  /* opacity: 0.8; */
}
.hero-csr .overlay-bg{
  height: 100%;
  width: 100%;
  /* background-color: rgba(59, 58, 58, 0.438); */
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.overlay-bg h1{
  font-size: 3.5rem;
  color: whitesmoke;
  text-align: center;
  font-style: italic;
}

@media (max-width: 500px) {
  .hero-csr {
    height: 35vh;
    /* background-size: 100% 100%; */
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-position: center;
  }
  .overlay-bg h1{
    font-size: 2rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 850px) {
  .hero-csr {
    height: 50vh;
    /* background-size: 100% 100%; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.contactus-sub-cont{
    display: flex;
    justify-content: space-evenly;
    background-color: whitesmoke;
    flex-wrap: wrap;
}

.contactus-boxs{
    margin: 2rem;
}
.contactus-boxs h3{
    font-size: 1.5rem;
    font-weight: 550;
    margin-bottom: 0.5rem;
}
.contactus-boxs p{
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.contactus-map{
    width: 100%;
}


.bg-contentDev{
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1641799080/orion-new-website/Banner_copy_h4u4gx.jpg');
    height: 95vh;
}
@media (max-width: 500px){
    .bg-contentDev{
        height: 40vh;
    }
}

.company-text-box {
  max-width: 60%;
  text-align: center;
}
.comp-text {
  margin: 1rem 0;
}
/*  */

.cont-dev-card-img-cont img {
  width: 100%;
  height: 18rem;
  object-fit: cover;
}
.cont-dev-card-sub-cont {
  padding: 1.5rem 3rem;
}

@media (max-width: 500px) {
  .cont-dev-card-img-cont img {
    height: 15rem;
  }
  .card-ls-abt-us{
    width: auto;
  }
}


.offer-card{
    background-color: white;
    width: 20rem;
    height: 15rem;
    color: #FFAA46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
}
.offer-card-img{
    width: 6.5rem;
}
.offer-card-title{
    margin-top: 1.5rem;
}
.cont-offer-img-card{
    height: 7rem;
    display: flex;
}
.bg-about-us{
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1640584645/orion-new-website/Home_-_New_copy_bumhc0.png');
    height: 95vh;
}

@media (max-width:500px){
    .bg-about-us{
        height: 35vh;
    }   
}
.bg-about-us{
    background-image: url('https://res.cloudinary.com/learnhat/image/upload/v1640584645/orion-new-website/Home_-_New_copy_bumhc0.png');
    height: 95vh;
}

@media (max-width:500px){
    .bg-about-us{
        height: 35vh;
    }   
}

.home {
    width: 100%;
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bg-home{
    /*
    background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1640324693/orion-new-website/main%20home/main-home-hero-banner_c0eghx.png");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/main-home-banner.png");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad/Studyabroad_1.png");
    */
    /*height: 60vh;*/
  }
  
  .bg-home-1{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_1.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg-home-2{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad.jpg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/studyabroad_2.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }

  .bg-home-3{
    /*
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_jobs_abroad.jpeg");
    */
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/nursing_1.png");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }

  .bg-home-4{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/oscg_ai_banner_2.jpg");
    height: 80vh;
    background-size:contain;
    /*
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    */
    background-repeat: no-repeat;
  }
  
  .bg-home-ignou{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou.jpeg");
    height: 80vh;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bg-home-ignou-2{
    background-image: url("https://orionfiles.s3.ap-south-1.amazonaws.com/orionedutech_website/ignou2.jpeg");
    height: 80vh;
    background-size:contain;
    background-repeat: no-repeat;
  }
  
  .home-data-cont{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .home-data-subcont-right{
    color: white;
    width: 35%;
    padding: 5rem;
  }
  .hero-title-1{
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-align: left;
  }
  .hero-title-2{
    font-size: 3.5rem;
    line-height: 3.8rem;
    font-weight: 500;
    text-align: left;
  }
  .hero-title-extra{
    font-weight: 500;
    font-size: 2.5rem;
    margin: 1rem 0; 
  }
  .inner-cont{
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1100px) {
    .home-data-subcont-right{
      padding: 2.5rem;
    }
    .hero-title-1{
      font-size: 1.7rem;
      line-height: 2.1rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 3.2rem;
      line-height: 3.5rem;
      font-weight: 500;
    }
    
  }
  @media (max-width: 500px) {
    .home {
      height: 40vh;
      /* background-size: 100% 100%; */
      background-repeat: no-repeat;
      background-position: 0% 20%;
      background-position: center;
    }
    .home-data-subcont-right{
      padding: 0.4rem;
      width: 45%;
    }
    .hero-title-1{
      font-size: 0.9rem;
      line-height: 1rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
    .hero-title-extra{
      font-weight: 500;
      font-size: 0.9rem;
      margin: 0.5rem 0; 
    }
    .inner-cont{
      margin:1rem;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 850px){
    .home {
      height: 65vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .home-data-subcont-right{
      padding: 2rem;
      width: 38%;
    }
    .hero-title-1{
      font-size: 1.5rem;
      line-height: 1.9rem;
      font-weight: 500;
    }
    .hero-title-2{
      font-size: 2.6rem;
      line-height: 3.3rem;
      font-weight: 500;
    }
  }
  
.offer-card{
    background-color: white;
    width: 20rem;
    height: 15rem;
    color: #FFAA46;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
}
.offer-card-img{
    width: 6.5rem;
}
.offer-card-title{
    margin-top: 1.5rem;
}
.cont-offer-img-card{
    height: 7rem;
    display: flex;
}
.buttons-cont {
  display: flex;
  /* background-color: red; */
  justify-content: space-around;
  width: 30rem;
  margin: auto;
}
.feedback-form-title {
  margin: auto;
  padding: 2rem;
  margin-top:1rem ;
  margin-bottom: -3rem;
}
.form-box-new {
  width: 36rem;
  min-height: 30rem;
}
.random-ptag {
  padding-left: 1rem;
}
.this-btn-cont {
  display: flex;
  justify-content: center;
}
.form-box-newest {
  width: 36rem;
  min-height: 30rem;
}

@media (max-width: 580px) {
  .buttons-cont {
    display: flex;
    flex-direction: column;
    height: 10rem;
    justify-content: space-around;
    width: auto;
    margin: auto;
  }
}

.title-ptag{
    margin-left: 1rem;
    /* font-weight: bold; */
    font-size: 1.1rem;
}
.email-validation {
    color: red;
    font-size: 1rem;
    padding-left: 17px;
}

