.dest-img-cont{
    position: relative;
    background-color: black;
    margin: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.destination-title{
    color: white;
    font-size: 1.7rem;
}

.dest-img-cont img{
    opacity: 0.6;
    margin: 0 !important;
    padding: 0 !important;
}

