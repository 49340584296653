.focus-area-card{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
    overflow: hidden;
    background-color: white;
}
.focus-area-sub-cont1{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.focus-area-img{
    height: 100%;
    width: auto;
}
.focus-area-sub-cont2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.5rem 0;
}
.fac-sd1{
    /* height: 60%; */
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.fac-sd2{
    height: 30%;
    margin: 0 2rem;
}
.card-sub-title-sub{
    color: #2F4152 ;
    margin-top: 0.2rem;
}


@media (max-width:600px){
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 22rem;
        width: 100%;
        border-radius: 20px;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
}

@media (max-width:400px){
    .card-holder-team{
        width: 100%;
    }
}
