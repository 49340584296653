
.focus-area-card-slim{
    border-radius: 20px;
    box-shadow: #00000040 0 8px 25px ;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    margin: 0.6rem;
    width: 11.3rem;
    
}


.focus-area-img-slim{
    width: 100%;
    height: 17rem;
    object-fit: cover;
}
.focus-area-sub-cont2-slim{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.fac-sd1-slim{
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.card-sub-title-sub{
    color: #2F4152 ;
    margin-top: 0.2rem;
}


/* @media (max-width:600px){
    
    .focus-area-card{
        flex-direction: column;
        height: 100% !important;
    }
    .focus-area-sub-cont1, .focus-area-sub-cont2{
        width: auto;
        overflow: visible;
    }
    .focus-area-img{
        height: 17rem;
        width: 100%;
        border-radius: 20px;
    }
    .fac-sd1{
        margin: 1rem 0;
    }
} */
@media (max-width:600px){
    .focus-area-card-slim{
        width: 9.5rem;
        margin: 0.4rem;
        height: 22rem ;
    }
    .focus-area-img-slim{
        height: 14rem;
    }
    .slim-title{
        font-size: 0.68rem;
        line-height: 1rem;
    }
    .slim-name{
        font-size: 0.68rem;
        line-height: 1.1rem;
    }
    .focus-area-sub-cont2-slim{
        height: auto;
    }
}

