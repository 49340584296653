.awards-card {
  background-color: var(--primary-cl);
  color: white;
}
.awards-card .award-img {
  height: 17rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}
.award-img img{
  width: 100%;
  object-fit: cover;
}
.awards-card .awards-card-desc {
  padding: 1.5rem;
  line-height: 1.3rem;
  font-size: 1rem;
  align-content: center;
  align-items: center;
}
.name-post{
  text-align: center;
  margin-top: 0.5rem;
}
.name-post-h5{
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.name-post-p{
  font-size: 0.8rem;
}
@media (max-width: 435px) {
  .awards-card {
    width: 17rem !important;
    height: auto !important;
  }
  .awards-card .award-img {
    height: 14rem !important;
    width: 17rem !important;
  }
  .awards-card img {
    height: 14rem !important;
    width: 17rem !important;
  }
}
